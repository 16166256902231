import React from 'react'

import { EngagementdoccasesecStyles } from './Engagementdoccasesec.styles'

function Engagementdoccasesec(props) {
  const { EngagementdocCaseProps } = props.EngagementdocCaseData

  return (
    <EngagementdoccasesecStyles>
      <div className="container">
        <h4>{EngagementdocCaseProps.subtitle}</h4>
        <h3>{EngagementdocCaseProps.title}</h3>
        <p>{EngagementdocCaseProps.para}</p>
      </div>
    </EngagementdoccasesecStyles>
  )
}

export default Engagementdoccasesec
