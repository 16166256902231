import styled from 'styled-components'
import { lg, md, xl } from 'config/variables'

export const EngagementdoccasesecStyles = styled.div`
 margin-bottom: 120px; 
  ${xl(`
      margin-bottom: 90px;
  `)}
  ${lg(`
      margin-bottom: 60px; 
  `)}
  ${md(`
      margin-bottom: 40px; 
  `)}
  .container {
    max-width:946px;
  }
  h4 {
    position:relative;
    padding-left:20px;
    margin: 0 0 14px;
    font-size: 15px;
    line-height:25px;
    font-weight: 600;
    text-transform:uppercase;
    color:#a3a3a3;
    border-left:3px solid #a3a3a3;
  }
`
