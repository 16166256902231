import React, { memo } from 'react'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import { xl, lg, md, sm, primaryColor } from 'config/variables'
import { accentConnectProps } from 'components/Props/accent-connect'
import Locaranimationbanner from 'components/Locaranimationbanner/Locaranimationbanner'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import Engagementdoccasesec from 'components/Engagementdoccasesec/Engagementdoccasesec'
import CasePowerTools from 'components/CasePowerTools/CasePowerTools'
import Casecontentfullwimg from 'components/Casecontentfullwimg/Casecontentfullwimg'
import Reliabilityinstancessec from 'components/Reliabilityinstancessec/Reliabilityinstancessec'
import Customerexperiencesec from 'components/Customerexperiencesec/Customerexperiencesec'
import Caseintegrate from 'components/Caseintegrate/Caseintegrate'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'

export const AccentConnectPage = styled.div`
.caseAnimationBanner {
    padding-bottom: 120px; 
    ${xl(`
       padding-bottom: 90px;
    `)}
    ${lg(`
       padding-bottom: 60px; 
    `)}
    ${md(`
       padding-bottom: 40px; 
    `)} 
    .img-block { 
      .bannerImage {
        width: 622px;
        position: relative;
        ${xl(`
          width: 460px; 
        `)}
        ${lg(`
            width: 351px; 
        `)}
        ${sm(`
           width: 280px; 
        `)}
        img {
          position:absolute;
          &.laptopScreen {
            position:relative;
          }   
          &.rotateImage { 
            bottom: 140px;
            left: 4px;
            transform: scale(1);
	          animation: scaleWithShadw 8s ease-in-out infinite;
            animation-delay: 4s;
            ${xl(`
              bottom: 90px;
              max-width: 70px;
            `)}
           ${lg(`
              bottom: 70px;
              max-width: 60px;
            `)}
           ${sm(`
              bottom: 70px;
              max-width: 40px;
            `)}
          }   
          &.actionBtn {
            right: 64px;
            bottom: 40px;
            transform: scale(1);
	          animation: scaleWithShadw 8s ease-in-out infinite;
            ${xl(`
              bottom: 30px;
              right: 20px;
              max-width: 100px;
            `)}
            ${lg(`
                bottom: 30px;
                right: 20px;
                max-width: 80px;
            `)}
              ${sm(`
                bottom: 30px;
                right: 20px;
                max-width: 50px;
            `)}
          }   
          &.containerImg {
            left: 32px;
            top: 7px;
	          animation: float 8s ease-in-out infinite; 
            z-index: 10;
            ${xl(`
              max-width: 200px;
              left: 30px; 
            `)}
            ${lg(`
              max-width: 130px;
              left: 26px;
            `)}
            ${sm(`
              max-width: 120px;
              left: 26px;
              top:0px;
            `)}
          }   
        }        
      }
    }
  }
  .caseintro-section {
    margin-bottom: 120px; 
    ${xl(`
       margin-bottom: 90px;
    `)}
    ${lg(`
       margin-bottom: 60px; 
    `)}
    ${md(`
       margin-bottom: 40px; 
    `)}
    .container {
      max-width: 1150px;
      h2 {
        position:relative;
        &::after {
          content:'';
          position:absolute;
          width:3px;
          left:-30px;
          height:100%;
          background: ${primaryColor};
          ${md(`
            left:-10px;
          `)}
        }
      }
    }
  }
  .casepower-section {
      padding:100px 0;
      background: #23262b;
      ${xl(`
        padding:80px 0;
      `)}
      ${lg(`
        padding:60px 0;
      `)}
      ${md(`
        padding:40px 0;
      `)}
      .powerContentRow {
        .powerToolsDetails {
          color:#fff;
        }
      }
    }
    .growingSalesSec {
      .content-container {
        max-width: 100%;
      }
    }
    .reliabilityinstancessec {
      background-image: linear-gradient(113deg, #d9e0fa 36%, #d9e0fa 20%, #b1bef3 104%);
      object-fit:contain;
      padding:100px 0;
      ${xl(`
        padding:80px 0;
      `)}
      ${lg(`
        padding:60px 0;
      `)}
      ${md(`
        padding:40px 0;
      `)}
      .track-content {
        margin:0px;
      }
    }
  @keyframes scaleWithShadw {
    0% { 
      transform: scale(1);
      filter: drop-shadow(0px 0px 5px rgba(72,132,243,0.2)); 
    }
    50% { 
      transform: scale(1.1);
      filter: drop-shadow(0 10px 10px rgba(72,132,243,0.2)); 
    }
    100% { 
      transform: translatey(1.2);
       filter: drop-shadow(0px 0px 5px rgba(72,132,243,0.2)); 
    }
  }
`
const AccentConnect = memo(props => {
  const { data } = props
  const { location } = props

  let ResultImage = [props.data.acResultScreen.childImageSharp.fluid]
  let dashboardScreen = [props.data.dashboardScreen.childImageSharp.fluid]
  let growingDaigram = [props.data.growingDaigram.childImageSharp.fluid]
  let gSearchImage = [props.data.gSearchImage.childImageSharp.fluid]
  let acAwsImage = [props.data.acAwsImage.childImageSharp.fluid]
  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="Accent Connect"
        description=""
        keywords={[`simform`]}
        meta={[
          {
            name: 'ROBOTS',
            content: 'NOINDEX, NOFOLLOW',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <AccentConnectPage>
        <Locaranimationbanner bannerdata={accentConnectProps} />
        <Caseintroduction
          caseintrodata={accentConnectProps}
          {...props}
          showFullimages={false}
        />
        <div className="lazy-load-div">
          <Engagementdoccasesec EngagementdocCaseData={accentConnectProps} />
          <CasePowerTools
            CasePowerToolsdata={accentConnectProps.functionalSolutionProps}
            items={ResultImage}
          />
        </div>
        {showContent && (
          <>
            <Casecontentfullwimg
              casecontentfullwdata={accentConnectProps.casecontentfullwProps}
              fluid={dashboardScreen}
            />
            <div className="growingSalesSec">
              <Casecontentfullwimg
                casecontentfullwdata={accentConnectProps.growingSalesProps}
                fluid={growingDaigram}
              />
            </div>
            <Reliabilityinstancessec
              ReliabilityinstancesData={accentConnectProps.amazonSNSProps}
              {...props}
              fluid={gSearchImage}
            />
            <Customerexperiencesec
              CustomerExperienceProps={accentConnectProps.dataSecurityProps}
              {...props}
              fluid={acAwsImage}
            />
            <Caseintegrate caseintegratedata={accentConnectProps} {...props} />
            <Relatedcaselist
              relatedcaselistdata={accentConnectProps}
              {...props}
            />
            <Casecontactcardinfo Casecontactcardinfodata={accentConnectProps} />
          </>
        )}
      </AccentConnectPage>
    </Layout>
  )
})

export default AccentConnect

export const pageQuery = graphql`
  query {
    bannerImage: file(
      relativePath: { regex: "/accentconnect-banner-image@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    businessFullImage: file(
      relativePath: { regex: "/way-point-intro-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    acResultScreen: file(relativePath: { regex: "/ac-result-thumb@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 522) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dashboardScreen: file(
      relativePath: { regex: "/ac-dashboard-screen@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1123) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    growingDaigram: file(
      relativePath: { regex: "/ac-growing-daigram@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1050) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    gSearchImage: file(relativePath: { regex: "/ac-gsearch-image@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 563) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    acAwsImage: file(relativePath: { regex: "/ac-aws-s3-image@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 502) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    integrateImg: file(
      relativePath: { regex: "/ac-crm-Integration-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 873) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/transtms-casestudies-thumb@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase2: file(
      relativePath: { regex: "/roundr-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
