import React from 'react'
import { images } from 'config/images'

export const accentConnectProps = {
  locarBannerProps: {
    heading: 'Accent Connect',
    bannerPara: 'Sales Enablement tool to take your sales to next level',
    BannerAlt: 'Accent Connect Case Study',
    locarBannerImage: [
      {
        className: 'laptopScreen',
        imgUrl: images.acIntroHomeScreen,
      },
      {
        className: 'rotateImage',
        imgUrl: images.acIntroConnect,
      },
      {
        className: 'actionBtn',
        imgUrl: images.acintrobucket,
      },
      {
        className: 'containerImg',
        imgUrl: images.acIntroElasticksearch,
      },
    ],
  },
  introducrtionContent: {
    maintile: 'Introduction',
    mainpara: (
      <>
        65% of the sales rep says that the absence of the right marketing
        content reduces their ability to sell efficiently. Accent Connect, a
        smart content management & distribution tool, is solving this challenge
        by empowering today's sales teams with on-the-go access to marketing
        resources & quick customization features to win prospective clients and
        beat competitors.
        <br />
        <br />
        Accent Technologies is a cloud-based sales enablement company that
        combines content delivery with data-driven sales performance management
        to help customers hone sales execution and improve win rates.
      </>
    ),
  },
  EngagementdocCaseProps: {
    subtitle: 'Engagement',
    title: 'Customize and access marketing documents on-the-go',
    para: (
      <>
        The main goal of any sales team is to make sales happen, and behind
        every transaction follows multiple sales interactions. These include a
        sales pitch, engaging buyers with marketing and product management
        material, legal documents, promotion offer templates, etc. Depending on
        the buyer's sales journey, the team needs to be quick in responding to
        prospects with relevant resources.
        <br />
        <br />
        Without sales enablement, finding the right resource in a resource
        dependent sales cycle is challenging. Most resources are scattered on
        different platforms, which makes resource updating and its usage in
        sales and onboarding new team members very difficult. By identifying
        this gap between the sales teams and marketing departments, Accent
        wanted simform to develop a sales enablement tool that can give
        searchable resource access with customization features to the sales team
        on-the-go.
      </>
    ),
  },
  functionalSolutionProps: {
    CasePowerToolsDetails: [
      {
        Title: 'Result',
        para: (
          <>
            After working closely with Accent, we developed a smart content
            management & distribution tool that empowered sales teams with{' '}
            <strong>
              powerful content search, intelligent content suggestions
            </strong>
            , and instant <strong>access to resources</strong> from anywhere.{' '}
            <br />
            <br />
            After Accent Connect was deployed into the market, it quickly became
            one of the four pillars of superior sales execution for Accent
            Technologies. The solution is performing at very high levels for
            retail wealth management and institutional asset management firms.
          </>
        ),
      },
    ],
  },
  casecontentfullwProps: {},
  growingSalesProps: {
    heading: 'Growing sales enablement with robust android & iOS applications',
    para:
      'Reducing cross-department dependencies was one of our primary goals while developing a solution for sales representatives. On average, the sales team interacts 15 to 20 times or more before closing a client. It includes relationship-building activities that require a lot of content, going to the marketing departments for customizing a document, or asking for resources is futile.',
    imgCaption: 'Scalable system architecture built on AWS',
    bottomPara: (
      <>
        Hence, we developed high performing native <strong>Android</strong> and{' '}
        <strong>iOS applications</strong> with backend on PHP + MySQL which is a
        widely used combination when it comes to server side programming due to
        its simplicity and ease of use. Using mobile apps, Accent connect can
        centralize, personalize, and deliver content to the sales team on-the-go
        from any place and any device possible.
      </>
    ),
  },
  amazonSNSProps: {
    trackheading: 'Powerful Google-like search with Elasticsearch',
    ReliabilityinstancesDetails: [
      {
        trackbottomPara: (
          <>
            Prospects come with unique queries and demand laser-sharp solutions.
            To answer the queries, sales representatives needed keyword-based
            search capabilities in the app using which they can scroll through
            documents, PDFs, presentations, etc. To implement{' '}
            <strong>google-like search</strong> ability, we integrated
            Elasticsearch, a highly scalable open-source full-text search
            engine.
            <br />
            <br />
            Elasticsearch allowed sales reps to{' '}
            <strong>
              search by keywords and metadata of the file and process
            </strong>{' '}
            queries in real-time, delivering speedy solutions, and closing 5x
            more clients than before.
          </>
        ),
      },
    ],
  },
  dataSecurityProps: {
    CustomerexperienceDetails: [
      {
        mainTitle: (
          <>
            High Data Security &<br /> powerful Storing Capacity with AWS S3
          </>
        ),
        para: (
          <>
            When developing a content-rich platform, such as Accent, data
            storage, and data security is of high importance as data breaches
            can cost companies millions. Hence, to provide users with a scalable
            and secure place to store documents, files, PDFs, etc., we turned to{' '}
            <strong>AWS S3</strong>. AWS S3 provides server side encryption
            where S3-managed keys encrypts files before saving it to disk and
            decrypts it when users request access to the file. AWS S3 made it
            extremely simple to store and retrieve files from any device,
            proving to be a highly scalable solution.
          </>
        ),
      },
    ],
  },
  caseIntegrateProps: {
    heading:
      'Fetch & Share content at rapid speed with Salesforce CRM integration',
    para: (
      <>
        While easy access to marketing resources is important, directly using
        the resources during pitching clients or while following up with them is
        also a critical sales function. Hence, we integrated Salesforce CRM to
        easily find existing clients and share marketing materials with them
        directly from the platform. . By integrating Salesforce with Accent,
        sales teams gain the{' '}
        <strong>flexibility to fetch and share content</strong> during the sales
        interactions and in live conferences.
      </>
    ),
  },
  relatedcaselistProps: {
    heading: 'Related Case Studies',
    caselistProps: [
      {
        title: 'TransTMS',
        para: (
          <>
            Automating day-to-day functions of today's logistics professionals
          </>
        ),
        className: 'soft-pink-two',
        link: '/case-studies/trans-tms/',
      },
      {
        title: 'Roundr',
        para: (
          <>
            An end-to-end technology stack to boost South Africa's Real Estate
            business
          </>
        ),
        className: 'light-peach-five',
        link: '/roundr-real-estate-technology/',
      },
    ],
  },
  CasecontactcardinfoProps: {
    CTACard: {
      title: (
        <>
          Speak to our experts to unlock the value Mobility, IoT, and Data
          Insights!
        </>
      ),
      buttonName: 'Contact Us',
      buttonLink: '/contact/',
    },
  },
}
